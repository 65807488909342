import * as React from 'react';

const routes = [
    {
        name: 'Home',
        path: '/',
    },
    {
        name: 'About',
        path: '/about',
    },
];

export default class HeadLine extends React.Component {
    render() {
        return (
            <div 
            className = 'Headline'>
                <ul>
                    <h1>React Router</h1>
                    { routes.map((route, index) => (
                        <a
                            className = 'HeadLine-Item' 
                            key = {index} 
                            href = {route.path}>{route.name}
                        </a>
                    ))}
                </ul>
            </div>
        );
    }
}